import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { OnboardingProvider } from '../../../context/OnboardingContext';
import ParentOnboardingStepOne from './ParentOnboardingStepOne/ParentOnboardingStepOne';
import ParentSuccess from './ParentSuccess/ParentSuccess';

const ParentOnboardingRoutes = () => {
    return (
        <OnboardingProvider>
            <Routes>
                <Route path="step1" Component={ParentOnboardingStepOne} />
                <Route path="success" Component={ParentSuccess} />
            </Routes>
        </OnboardingProvider>
    );
};

export default ParentOnboardingRoutes;