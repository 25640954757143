import React, { useState} from 'react';
import './ReviewForm.css';

const ReviewForm = ({ onSubmit, isVisible }) => {
    const [visitDate, setVisitDate] = useState('');
    const [title, setTitle] = useState('');
    const [review, setReview] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setVisitDate(newDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple check - if any is empty, don't submit
        if (!visitDate || !title.trim() || !review.trim() || !isVerified) {
            return;
        }

        onSubmit({ visitDate, title, review, isVerified });
    };

    return (
        <div className={`review-form-container ${isVisible ? 'visible' : ''}`}>
            <div className='rf-content'>
                <div className='rf-section'>
                    <h2 className='rf-title'>Когда Вы нас посещали?</h2>
                    <input
                        type='date'
                        value={visitDate}
                        onChange={handleDateChange}
                        className='rf-date-input'
                        lang='ru-RU'
                        data-date-format='DD.MM.YYYY'
                        placeholder='ДД.ММ.ГГГГ'
                        required
                    />
                </div>

                <div className='rf-section'>
                    <h2 className='rf-title'>Добавьте заголовок к отзыву</h2>
                    <input
                        type='text'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className='rf-input'
                        placeholder='Например: Самая лучшая школа!'
                        required
                    />
                </div>

                <div className='rf-section'>
                    <h2 className='rf-description'>Оставьте Ваш отзыв</h2>
                    <textarea
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                        className='rf-textarea'
                        placeholder='Расскажите о Вашем опыте другим родителям'
                        required
                    />
                </div>

                <div className='rf-verify-section'>
                    <label className='rf-verify-label'>
                        <input
                            type='checkbox'
                            checked={isVerified}
                            onChange={(e) => setIsVerified(e.target.checked)}
                            className='rf-checkbox'
                            required
                        />
                        <span className='rf-verify-text'>
                            Я подтверждаю, что данный отзыв основан на моем личном опыте и является моим исключительным мнением об этой Организации, 
                            и что у меня нет личных или деловых отношений с этой Организацией, и мне не предлагали никакого вознаграждения за написание этого отзыва. 
                            Я понимаю, что ДляДетей.ру придерживается политики нетерпимости к фальшивым отзывам, и принимаю это.
                        </span>
                    </label>
                </div>

                <button 
                    className='rf-submit-button' 
                    type='submit' 
                    onClick={handleSubmit}
                    disabled={!isVerified || !title.trim() || !review.trim() || !isVerified}
                >
                    Оставить отзыв
                </button>
            </div>
        </div>
    );
};

export default ReviewForm;