import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import { OnboardingContext } from "../../../../context/OnboardingContext";
import "./ParentSuccess.css";

const ParentSuccess = () => {
  //const { onboardingData } = useContext(OnboardingContext);
  //const { firstName } = onboardingData;
  const [firstName, setFirstName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Get firstName from localStorage from ParentOnboardingStepOne on mount
    const userData = localStorage.getItem('parentSignupData');
    if (userData) {
        const { firstName } = JSON.parse(userData);
        setFirstName(firstName);
        // Clean up after reading
        localStorage.removeItem('parentSignupData');
    }
  }, []);

  const handleNavigateToMap = () => {
    navigate("/");
  };

  const handleNavigateToDashboard = () => {
    navigate("/parent/dashboard");
  };

//   useEffect(() => {
//     setOnboardingData((prevData) => ({
//       ...prevData,
//       firstName,
//     }));
//   }, [firstName]);


  return (
    <div className='parent-onboarding-success'>
      <h2>{`${firstName}, Ваш аккаунт успешно создан!`}</h2>
      <div className='pos-value-props'>
        <p className='pos-main-value'>
          Теперь вы можете не только находить лучшие детские занятия и услуги на
          карте, но и:
        </p>
        <div className='pos-features'>
          <div className='pos-feature'>
            <span>🌟</span>
            <p>
              Сохранять и добавлять в избранное интересные занятия, чтобы не
              искать их заново
            </p>
          </div>
          <div className='pos-feature'>
            <span>💬</span>
            <p>
              Делиться личным опытом с другими родителями, оставляя честные
              отзывы
            </p>
          </div>
          <div className='pos-feature'>
            <span>🎯</span>
            <p>
              Помогать другим родителям найти лучшие занятия, оставляя
              справедливые оценки
            </p>
          </div>
        </div>

        <p className='pos-telegram'>
          А чтобы быть в курсе последних новостей, подписывайтесь на наш{" "}
          <a
            href='https://t.me/ddeteyru'
            target='_blank'
            rel='noopener noreferrer'
            className='pos-telegram-href'
          >
            Телеграм канал
          </a>
          .
        </p>
      </div>

      <div className='pos-buttons'>
        <button
          onClick={handleNavigateToMap}
          className='pos-nav-to-map-button'
        >
          Вернуться на карту
        </button>
        <button
          onClick={handleNavigateToDashboard}
          className='pos-nav-to-dashboard-button'
        >
          Перейти в личный кабинет
        </button>
      </div>

      <div className='os-confetti-container'>
        {[...Array(75)].map((_, i) => (
          <div key={i} className={`confetti confetti-${i % 3}`}></div>
        ))}
      </div>
    </div>
  );
};

export default ParentSuccess;
