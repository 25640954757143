import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../../firebase/FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import ParentReviewsList from "../ParentReviewsList/ParentReviewsList";

import "./ParentDashboard.css";

const ParentDashboard = () => {
  const [parentData, setParentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchParentData = async (parentId) => {
    try {
      const parentDocRef = doc(db, "parents", parentId);
      const parentDoc = await getDoc(parentDocRef);

      if (parentDoc.exists()) {
        setParentData(parentDoc.data());
      }
    } catch (error) {
      console.error("DEBUG! ---> Error fetching parent data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (parent) => {
        if (parent) {
            fetchParentData(parent.uid);
        } else {
            setError('Пожалуйтса, войдите в систему');
            setLoading(false);
        }
    });
    return () => unsubscribe();
  }, []);

  if (loading) return <div>Загружаю...</div>;
  if (error) return <div>Произошла ошибка: {error}</div>;

  return (
    <div className='parent-dashboard'>
      <div className='pd-list-title'>
        <h2>Добро пожаловать, {parentData?.firstName || "Гость"}</h2>
      </div>
      <ParentReviewsList />
    </div>
  );
};

export default ParentDashboard;
