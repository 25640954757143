import React, { useState } from "react";
import "./RatingStars.css";

const StarIcon = ({
  filled,
  size = 24,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 22 20'
    fill={filled ? "#72147e" : "none"}
    stroke={filled ? "#72147e" : "#d1d5db"}
    strokeWidth='1.2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='star-icon-transition-colors'
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <path d='M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z' />
  </svg>
);

const RatingStars = ({ rating, onRatingChange, size = 24, interactive = false, user }) => {
  const [hoverRating, setHoverRating] = useState(0);
  const [hasClickedStar, setHasClickedStar] = useState(false);
  // Convert rating into a valid number between 0-5
  const validRating = Math.min(5, Math.max(0, Number(rating) || 0));

  const handleStarClick = (starClicked) => {
    if (interactive && onRatingChange) {
        onRatingChange(starClicked); // THIS updates parent state
        setHoverRating(0); // reset hover state after click 
        setHasClickedStar(true);
    }
  };

  return (
    <div className='rating-stars-container'>
      <div className='rating-stars'>
        {[1, 2, 3, 4, 5].map((star) => (
          <StarIcon
            key={star}
            size={size}
            filled={star <= (hoverRating || validRating)}
            onClick={() => handleStarClick(star)}
            onMouseEnter={() => interactive && setHoverRating(star)}
            onMouseLeave={() => interactive && setHoverRating(0)}
          />
        ))}
      </div>
      {interactive && (
        <p className='rating-stars-text-prompt'>
          {!user && hasClickedStar
            ? 'Только зарегистрированные пользователи могут оставлять отзывы'
            : 'Расскажите другим родителям о Вашем опыте'
          }
        </p>
      )}
    </div>
  );
};

export default RatingStars;
