import React from 'react';
import './LoginTypeToggle.css';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginTypeToggle = ({ activeType }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Check if the user in signup flow
    const isSignupFlow = location.pathname.includes('onboarding');

    // Dynamic navigation paths based on context
    const getNavigationPath = (userType) => {
        return isSignupFlow
        ? `/${userType}/onboarding/step1`
        : `/${userType}/login`;
    };

    return (
        <div className='login-type-toggle'>
            <button
                className={`view-btn ${activeType === 'parent' ? 'active' : ''}`}
                onClick={() => navigate(getNavigationPath('parent'))}
            >
                Родители
            </button>
            <button
                className={`view-btn ${activeType === 'activityProvider' ? 'active' : ''}`}
                onClick={() => navigate(getNavigationPath('activity-provider'))}
            >
                Организация
            </button>
        </div>
    );
};

export default LoginTypeToggle;