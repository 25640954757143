import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/FirebaseConfig';
import { useAuth } from '../../../../hooks/useAuth';
import RatingStars from '../../../global/RatingStars/RatingStars';
import './ActivityProviderParentReviewsList.css';

const ActivityProviderParentReviewsList = () => {
    const { user } = useAuth();
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const fetchActivityReviews = async () => {
        try {
            // Query Reviews for this specific activity
            const parentReviewsQuery = query(
                collection(db, 'reviews'),
                where('activityProviderId', '==', user.uid)
            );
            const parentReviewsSnapshot = await getDocs(parentReviewsQuery);

            // Get all reviews data + parent names
            const parentReviewsData = parentReviewsSnapshot.docs.map(reviewDoc => ({
                    id: reviewDoc.id,
                    ...reviewDoc.data(),
            }));

            // Sort reviews: promoted first, then by date
            const sortedParentReviews = parentReviewsData.sort((a, b) => {
                if (a.topReview && !b.topReview) return -1;
                if (!a.topReview && b.topReview) return 1;
                return new Date(b.visitDate) - new Date(a.visitDate);
            });

            setReviews(sortedParentReviews);
        } catch (error) {
            console.error('DEBUG! ---> Error in fetchActivityReviews:', error);
            setError(`Не удалось загрузить отзывы: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchActivityReviews();
        }
    }, [user]);

    const handleRespondToReview = async (reviewId) => {
        try {
            // Add response logic here...
            console.log('Respond button clicked. Responding to review:', reviewId);
        } catch (error) {
            console.error('DEBUG! ---> Error responding to review:', error.message);
        }
    };

    const handlePromoteReview = async (reviewId) => {
        try {
            const review = reviews.find(r => r.id === reviewId);

            // If removing from Top, update this review
            if (review.topReview) {
                const parentReviewRef = doc(db, 'reviews', reviewId);
                await updateDoc(parentReviewRef, {
                    topReview: false,
                    topReviewDate: null
                });
            } else {
            // If adding to Top, check how many top reviews we already have
            const currentTopReviews = reviews.filter(review => review.topReview).length;
            if (currentTopReviews >= 3) {
                setError('Максимально количество Топ Отзывов: 3');
                return;
            }

            const parentReviewRef = doc(db, 'reviews', reviewId);
            await updateDoc(parentReviewRef, {
                topReview: true,
                topReviewDate: new Date()
            });
        }

            await fetchActivityReviews();
        } catch (error) {
            console.error('DEBUG! ---> Error prmoting review:', error);
            setError(`Не удалось выделить отзыв --> ${error.message}`);
        }
    };

    if (loading) return <div>Загружаю...</div>
    if (error) return <div className='aprl-error'>{error}</div>

    return (
        <div className='activity-provider-parents-reviews-list'>
          <div className='aprl-list-title'>
            <h2>Отзывы <span className='aprl-list-count'>({reviews.length})</span></h2>
          </div>
          
          {reviews.length === 0 ? (
            <div className='aprl-empty-state'>
              <p>У Вас пока нет отзывов</p>
            </div>
          ) : (
            <div className='aprl-reviews-container'>
              {reviews.map((review) => (
                <div key={review.id} className={`aprl-review-card ${review.topReview ? 'top' : ''}`}>
                  <div className='aprl-review-header'>
                    <h3>{review.parentName || 'Аноним'}</h3>
                    <RatingStars rating={review.rating} size={24} interactive={false} />
                  </div>
                  
                  <div className='aprl-review-content'>
                    <span>{review.title}</span>
                    <p>{review.review}</p>
                  </div>
                  
                  <div className='aprl-review-footer'>
                    <span className='aprl-review-date'>
                      {formatDate(review.visitDate)}
                    </span>
                    <div className='aprl-review-actions'>
                      <button
                        className='aprl-respond-button'
                        onClick={() => handleRespondToReview(review.id)}
                      >
                        Ответить
                      </button>
                      <button
                        className='aprl-promote-button'
                        onClick={() => handlePromoteReview(review.id)}
                      >
                        {review.topReview ? 'Убрать из ТОПа' : 'Вывести в ТОП'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
};

export default ActivityProviderParentReviewsList;