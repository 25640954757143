import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase/FirebaseConfig';
import { useAuth } from '../../../hooks/useAuth';
import RatingStars from '../../global/RatingStars/RatingStars';

import './ParentReviewsList.css';

const ParentReviewsList = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

        const fetchParentReviews = async () => {
            if (!user) return;

            try {
                const parentReviewsQuery = query(
                    collection(db, 'reviews'),
                    where('parentId', '==', user.uid)
                );
                const parentReviewsSnapshot = await getDocs(parentReviewsQuery);

                const parentReviewsWithDetails = await Promise.all(
                    parentReviewsSnapshot.docs.map(async (reviewDoc) => {
                        const parentReviewData = reviewDoc.data();
                        const activityDoc = await getDoc(doc(db, 'activities', parentReviewData.activityId));

                        return {
                            id: reviewDoc.id,
                            ...parentReviewData,
                            businessName: activityDoc.data().businessName
                        };
                    })
                );

                setReviews(parentReviewsWithDetails);
            } catch (error) {
                console.error('DEBUG! ---> Error fetching reviews:', error);
            } finally {
                setLoading(false);
            }
        };

    useEffect(() => {
        fetchParentReviews();
    }, [user]);

    const handleEdit = async (reviewId) => {
        try {
            // First, find the review to edit
            const reviewToEdit = reviews.find(review => review.id === reviewId);
            if (!reviewId) return;

            // Here will be modal or inside form for editing
            // For now, just log the action
            console.log('DEBUG ---> Editing review:', reviewId);

            // Now, we will update the review in the Firestore
            await updateDoc(doc(db, 'reviews', reviewId), {
            // Add fields to update
            // title: newTitle,
            // review: newReview,
            // rating: newRating,
            // etc...
            });

            // Refresh the reviews list
            await fetchParentReviews();
        } catch (error) {
            console.error('DEBUG! ---> Error editing review:', error);
            setError('Не удалось отредактировать отзыв');
        }
    }; 

    const handleDelete = async (reviewId) => {
        if (window.confirm('Вы уверены, что хотите удалить этот отзыв?')) {
            try {
                // Delete the review
                await deleteDoc(doc(db, 'reviews', reviewId));

                // Update the parent's review count in activities collection
                const reviewToDelete = reviews.find(review => review.id === reviewId);
                if (reviewToDelete) {
                    const activityRef = doc(db, 'activities', reviewToDelete.activityId);
                    const activityDoc = await getDoc(activityRef);

                    if (activityDoc.exists()) {
                        await updateDoc(activityRef, {
                            totalReviews: (activityDoc.data().totalReviews || 1) - 1
                        });
                    }
                }

                // Update local state
                setReviews(prevReviews => prevReviews.filter(review => review.id !== reviewId));
                setError(null);

            } catch (error) {
                console.log('DEBUG! ---> Error deleteing review:', error);
                setError('Не удалось удалить отзыв');
            }
        }
    };

    if (loading) return <div>Загружаю...</div>;
    if (error) return <div className='prl-error'>{error}</div>;

    return (
        <div className='parent-reviews-list'>
            <div className='prl-list-title'>
                <h2>Мои Отзывы <span className='prl-list-count'>({reviews.length})</span></h2>
            </div>

            {reviews.length === 0 ? (
                <div className='prl-empty-state'>
                    <p>Вы пока не оставили ни одного отзыва</p>
                </div>
            ): (
                <div className='prl-reviews-container'>
                    {reviews.map((review) =>(
                        <div key={review.id} className='prl-review-card'>
                            <div className='prl-review-header'>
                                <h3>{review.businessName}</h3>
                                <RatingStars rating={review.rating} size={24} interactive={false} />
                            </div>

                            <div className='prl-review-content'>
                                <span>{review.title}</span>
                                <p>{review.review}</p>
                            </div>

                            <div className='prl-review-footer'>
                                <span className='prl-review-date'>
                                    {formatDate(review.visitDate)}
                                </span>
                                <div className='prl-review-actions'>
                                    {/* <button
                                        className='prl-edit-button'
                                        onClick={() => handleEdit(review.id)}
                                    >
                                        Редактировать
                                    </button> */}
                                    <button
                                        className='prl-delete-button'
                                        onClick={() => handleDelete(review.id)}
                                    >
                                        Удалить отзыв
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ParentReviewsList;