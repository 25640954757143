import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/FirebaseConfig';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { updateEmail, updatePassword, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { useAuth } from '../../../hooks/useAuth'; 
import './ParentAccountSettings.css';

const ParentSettings = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    contactNumber: '',
    email: '',
  });
  
  const { user, loading, logout } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      try {
        const userDoc = await getDoc(doc(db, 'parents', user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData({
            firstName: data.firstName || '',
            middleName: data.middleName || '',
            lastName: data.lastName || '',
            contactNumber: data.contactNumber || '',
            email: data.email || user.email,
          });
        }
      } catch (error) {
        console.error("DEBUG: ---> Error fetching user data:", error);
        setError('Ошибка загрузки данных пользователя');
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchUserData();
    } else if (!loading) {
      navigate('/parent/login')
    }
  }, [user, loading, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      if (user) {
        // Update Firestore document
        await updateDoc(doc(db, 'parents', user.uid), {
          firstName: userData.firstName,
          middleName: userData.middleName,
          lastName: userData.lastName,
          contactNumber: userData.contactNumber,
        });

        // Update email if changed
        if (user.email !== userData.email) {
          await updateEmail(user, userData.email);
        }

        // Update password if provided
        if (newPassword) {
          await updatePassword(user, newPassword);
        }

        setSuccess("Ваши данные были успешно обновлены");
      }
    } catch (error) {
      setError('Ошибка! Не удалось обновить данные');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Вы уверены, что хотите удалить свой аккаунт?")) {
      try {
        setIsLoading(true);
        if (user) {
          // Re-authenticate user before deletion
          const credential = EmailAuthProvider.credential(user.email, currentPassword);
          await reauthenticateWithCredential(user, credential);

          // Delete Firestore document
          await deleteDoc(doc(db, 'parents', user.uid));

          // Delete user account
          await deleteUser(user);

          // Clear stored data
          localStorage.removeItem('user');
          sessionStorage.removeItem('user');

          // Logout and redirect
          await logout();
          navigate('/parent/login')
        }
      } catch (error) {
        console.error("DEBUG: ---> Error deleting account:", error);
        setError('Ошибка! Не удалось удалить пользователя');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (loading || isLoading) {
    return <div className="ps-loading">Загрузка...</div>;
  }

  const FormDivider = () => <hr className="ps-form-divider" />;

  return (
    <div className="parent-settings">
      <div className="ps-title">
        <h2>Настройки Аккаунта</h2>
      </div>
      {error && <div className="ps-error">{error}</div>}
      {success && <div className="ps-success">{success}</div>}
      <div className="ps-form-container">
        <form onSubmit={handleSubmit}>
          <div className="ps-form-group">
            <label htmlFor="lastName">Фамилия</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={userData.lastName}
              onChange={(e) => setUserData({...userData, lastName: e.target.value})}
              placeholder="Пушкин"
            />
          </div>
          <div className="ps-form-group">
            <label htmlFor="firstName">Имя</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={userData.firstName}
              onChange={(e) => setUserData({...userData, firstName: e.target.value})}
              placeholder="Александр"
            />
          </div>
          <div className="ps-form-group">
            <label htmlFor="middleName">Отчество</label>
            <input
              type="text"
              id="middleName"
              name="middleName"
              value={userData.middleName}
              onChange={(e) => setUserData({...userData, middleName: e.target.value})}
              placeholder="Сергеевич"
            />
          </div>
          <div className="ps-form-group">
            <label htmlFor="contactNumber">Контактный телефон</label>
            <input
              type="tel"
              id="contactNumber"
              name="contactNumber"
              value={userData.contactNumber}
              onChange={(e) => setUserData({...userData, contactNumber: e.target.value})}
              pattern="[+\d]+"
              placeholder="8923444488"
            />
          </div>
          <div className="ps-form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={(e) => setUserData({...userData, email: e.target.value})}
              placeholder="al.pushkin@yandex.ru"
            />
          </div>
          <div className="ps-form-group">
            <label htmlFor="newPassword">Поменять пароль</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Введите новый пароль"
            />
          </div>
          <button type="submit" className="ps-submit-button" disabled={isLoading}>
            {isLoading ? 'Обновляю...' : 'Обновить данные'}
          </button>
        </form>

        <FormDivider />

        <div className="ps-danger-zone">
          <h3>Удаление аккаунта</h3>
          <p>Удаление аккаунта навсегда удалит все данные с ДляДетей.ру и их невозможно будет восстановить.</p>
          <input
            type="password"
            placeholder="Введите пароль"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <button onClick={handleDeleteAccount} className="ps-delete-button">
            Удалить аккаунт
          </button>
        </div>
      </div>
    </div>
  );
};

export default ParentSettings;