import React, { useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/FirebaseConfig';
import { FaComments, FaCog, FaSignOutAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { useAuth } from '../../../hooks/useAuth';
import './ParentPanel.css';

const ParentPanel = () => {
    const  { user, loading, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!loading && !user) {
            navigate('/parent/login')
        }
    }, [user, loading, navigate]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/parent/login');
        } catch (error) {
            console.error("Logout error:", error);
        }
    };
    const icons = [
        { icon: FaMapMarkerAlt, label: "Карта", path: "/" },
        { icon: FaComments, label: "Мои отзывы", path: "/parent/dashboard" },
        { icon: FaCog, label: "Настройки", path: "/parent/account-settings" },
    ];

    const handleIconClick = async (path) => {
        if (path === "edit-activity") {
            // Fetch provider data to get activityId
            const parentDocRef = doc(db, 'parents', user.uid);
            const parentDoc = await getDoc(parentDocRef);

            if (parentDoc.exists() && parentDoc.data().activityId) {
                navigate(`/parent/${parentDoc.data().activityId}/edit`);
            } else {
                console.log("DEBUG! ---> No section found for this parent...");
            }
        } else {
        navigate(path);
        }
    };

    const isActive = (path) => {
        // For map icon
        if (path === '/') {
            return location.pathname === '/';
        }
        // For reviews 
        if (path === '/parent/dashboard') {
            return location.pathname === '/parent/dashboard';
        }
        // For settings
        if (path === '/parent/account-settings') {
        return location.pathname === '/parent/account-settings';
        }
        return false;
    };

    if (loading) return <div>Загружаю...</div>;
    //THIS prevents (or should prevent) rendering of child routes if the user is not authenticated
    if (!user) return null;

    return (
        <div className="activity-provider-panel">
            <div className="app-sidebar">
                <div className="app-profile-icon">
                    <img src='/dd_logo/ДляДетей.ру.png' alt='DD Logo' />
                </div>
                <div className="app-middle-icons">
                    {icons.map((IconItem, index) => (
                        <div
                            key={index}
                            className={`icon-wrapper ${isActive(IconItem.path) ? 'active' : '' }`}
                            onClick={() => handleIconClick(IconItem.path)}
                        >
                            <IconItem.icon className="icon" />
                        </div>
                    ))}
                </div>
                <div className="app-logout-icon">
                    <FaSignOutAlt className="icon" onClick={handleLogout} />
                </div>
            </div>

            <div className="app-main-content-container">
                <div className="app-main-content">
                    <Outlet />
                </div>
            </div>

        </div>
    );
};

export default ParentPanel;