import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../../firebase/FirebaseConfig";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, onAuthStateChanged } from "firebase/auth";
import { serverTimestamp, doc, writeBatch } from "firebase/firestore";
import { OnboardingContext } from "../../../../context/OnboardingContext";
import LoginTypeToggle from "../../../global/LoginTypeToggle/LoginTypeToggle";

import "./ParentOnboardingStepOne.css";

const ParentOnboardingStepOne = () => {
const { setOnboardingData } = useContext(OnboardingContext);

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setError("");
  }, [firstName, email, password]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // If user is already logged in, redirect to dashboard
            navigate('/parent/dashboard');
        }
    });

    return () => unsubscribe();
  }, [navigate]);

  const checkIfEmailInUse = async (email) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length > 0;
    } catch (error) {
      console.error("DEBUG: --> Error checking email:", error);
      return false;
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]/.test(password);
    const conditionsMet = [
      hasLowercase,
      hasUppercase,
      hasDigit,
      hasSpecialChar,
    ].filter(Boolean).length;

    return minLength && conditionsMet >= 3;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Validate email & password first
    if (!validateEmail(email)) {
      setError("Пожалуйста, введите правильный email адрес");
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Пароль должен содержать, минимум, 8 символов, в которых присутствует, как минимум, 1 цифра, 1 заглавная буква и 1 уникальный символ, например, #"
      );
      return;
    }

    setLoading(true);

    try {
      const emailInUse = await checkIfEmailInUse(email);
      if (emailInUse) {
        setError(
          "Этот email уже зарегистрирован. Пожалуйста, используйте другой email для регистрации."
        );
        setLoading(false);
        return;
      }

      const batch = writeBatch(db);

      // THIS creates parent account
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // THIS creates parent document in Firestore
      const parentDocRef = doc(db, "parents", user.uid);
      const parentData = {
        firstName,
        email,
        joinedDate: serverTimestamp(),
        lastActive: serverTimestamp(),
        savedActivities: [],
        // reviews: [],
        // rates: [],
        productUsage: {
            // Lifetime value detailed tracking
            activityInteractions: {
                activityMarkerClicks: 0,
                activityProviderPageViews: 0,
                keyActions: {
                    phone: 0,
                    email: 0,
                    whatsapp: 0,
                    telegram: 0
                }
            },
            specialOffers: {
                views: 0,
                clicks: 0,
                keyActions: {
                    phone: 0,
                    email: 0,
                    whatsapp: 0,
                    telegram: 0                    
                }
            },
            reviews: {
                written: 0,
                modalOpens: 0,
            },
            ratings: {
                given: 0,
                lastRating: null
            },
            // Monthly activity (minimal storage impact)
            monthlyActivity: {
                [`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`]: {
                    activityMarkerClicks: 0,
                    activityProviderPageViews: 0,
                    keyActionsTotal: 0,
                    specialOfferViews: 0,
                    specialOfferClicks: 0,
                    specialOfferKeyActions: 0,
                    reviewsCreated: 0,
                    ratingsGiven: 0,
                    daysActive: []
                }
            }
        }
      };

      batch.set(parentDocRef, parentData);
      await batch.commit();

      setOnboardingData({
        ...parentData,
        uid: user.uid,
        tempData: false
      });

      localStorage.setItem('parentSignupData', JSON.stringify({ firstName }));
      // Navigate to Success page
      navigate("/parent/onboarding/success");
    } catch (error) {
      console.error("DEBUG: Parent signup error:", error);
      setError("Регистрация не удалась. Попробуйте еще раз.");
    } finally {
      setLoading(false);
    }
  };

  const usps = [
    "• Оставляйте отзывы",
    "• Оценивайте занятия",
    "• Добавляйте в избранное",
  ];

  return (
    <>
      <div className='login-page-header'>
        <LoginTypeToggle activeType='parent' />
      </div>
      <div className='parent-signup'>
        <div className='ps-usp-container'>
          <h1>
            <span style={{ cursor: "pointer " }} onClick={() => navigate("/")}>
              ДляДетей.ру
            </span>{" "}
            это интерактивная карта для поиска детских занятий и услуг в вашем
            городе.
          </h1>
          <ul>
            {usps.map((usp, index) => (
              <li key={index}>{usp}</li>
            ))}
          </ul>
          <p>
            <span onClick={() => navigate("/terms-of-service")}>
              Правила Пользования{" "}
            </span>{" "}
            |{" "}
            <span onClick={() => navigate("/privacy-policy")}>
              Политика Конфиденциальности
            </span>
          </p>
        </div>
        <div className='ps-signup-form-container'>
          <h2>Создать аккаунт Родителя</h2>
          {error && <p className='ps-error'>{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className='ps-form-group'>
              <input
                type='text'
                id='firstName'
                placeholder='Как Вас зовут?'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className='ps-form-group'>
              <input
                type='email'
                id='email'
                placeholder='Ваш email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='ps-form-group'>
              <input
                type='password'
                id='password'
                placeholder='пароль из не менее 8 символов'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type='submit' disabled={loading}>
              {loading ? "Создаю аккаунт..." : "Создать аккаунт"}
            </button>
          </form>
          <p className='ps-login-link'>
            Уже есть аккаунт?{" "}
            <span onClick={() => navigate("/parent/login")}>Войти</span>
          </p>
        </div>
        {/* MOBILE */}
        <div className='ps-mobile-layout'>
          <div className='ps-mobile-usp-container'>
            <h1>
              <span onClick={() => navigate("/")}>ДляДетей.ру</span> это
              интерактивная карта для поиска детских занятий и услуг в вашем
              городе.
            </h1>
            <ul>
              {usps.map((usp, index) => (
                <li key={index}>{usp}</li>
              ))}
            </ul>
          </div>
          <div className='ps-mobile-signup-form-container'>
            <h2>Создать аккаунт Родителя</h2>
            {error && <p className='ps-error'>{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className='ps-mobile-form-group'>
                <input
                  type='text'
                  id='firstName'
                  placeholder='Ваше имя'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className='ps-mobile-form-group'>
                <input
                  type='email'
                  id='email'
                  placeholder='Ваш email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className='ps-mobile-form-group'>
                <input
                  type='password'
                  id='password'
                  placeholder='пароль из не менее 8 символов'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type='submit' disabled={loading}>
                {loading ? "Создаю аккаунт..." : "Создать аккаунт"}
              </button>
            </form>
            <p className='ps-mobile-login-link'>
              Уже есть аккаунт?{" "}
              <span onClick={() => navigate("/parent/login")}>Войти</span>
            </p>
          </div>
          <p>
            <span onClick={() => navigate("/terms-of-service")}>
              Правила Пользования{" "}
            </span>{" "}
            |{" "}
            <span onClick={() => navigate("/privacy-policy")}>
              Политика Конфиденциальности
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default ParentOnboardingStepOne;
