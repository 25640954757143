import React from 'react';
import './ReviewCallToAction.css';

const ReviewCallToAction = () => {
    return (
        <div className="review-cta">
            <button
                className='review-cta-button'
                onClick={() => window.location.href = '/parent/onboarding/step1'}
            >
                Создать аккаунт
            </button>
        </div>
    );
};

export default ReviewCallToAction;